import React, { useState, useCallback, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import PageContent from "../../../Layout/AppMain/PageContent";

import api from '../../../services/api';
import { ErrorToast } from '../../../Layout/AppMain/Components/Toast';
import { Row, Col, Container, Spinner } from 'reactstrap';
import { extractUrlParams, formatCpfCnpj, formatPhone } from 'nfutils';
import FormFooter from '../../../Layout/AppFooter/Components/FormFooter';
import Conciliations from './Components/Conciliations';
import PurchasesNotes from './Components/PurchasesNotes';
import InstallmentsReceive from './Components/InstallmentsReceive';
import InstallmentsPayment from './Components/InstallmentsPayment';
import CardsConciliations from './Components/CardsReceiveConciliations';

const GuestAccess = ({ setLoading, match, history }) => {
  const { company_hash } = match.params
  const params = extractUrlParams(history.location.search)
  const [companyData, setCompanyData] = useState();
  const [update, setUpdate] = useState(false);

  const [pendingMovs, setPendingMovs] = useState([]);
  const [movWithNote, setMovWithNote] = useState([]);

  const [pendingCardsReceive, setPendingCardsReceive] = useState([]);
  const [cardsReceiveWithNote, setCardsReceiveWithNote] = useState([]);

  const [ allowPurchases, setAllowPurchases ] = useState([]);

  const [ concilitaionsLoading, setConcilitaionsLoading ] = useState(false);
  const [ conciliationsCompensateCardsReceiveLoading, setConciliationsCompensateCardsReceiveLoading ] = useState(false);
  const [ purchasesLoading, setPurchasesLoading ] = useState(false);

  const [installmentsReceive, setInstallmentsReceive] = useState([]);
  const [installmentsReceiveLoading, setInstallmentsReceiveLoading] = useState(false);
  const [installmentsReceiveWithNote, setInstallmentsReceiveWithNote] = useState([]);

  const [installmentsPayment, setInstallmentsPayment] = useState([]);
  const [installmentsPaymentLoading, setInstallmentsPaymentLoading] = useState(false);
  const [installmentsPaymentWithNote, setInstallmentsPaymentWithNote] = useState([]);


  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(`requests/${company_hash}`);
      setCompanyData(response.data.company);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ErrorToast({ placeholder: "Erro ao buscar dados" });
    }
  }, [company_hash, setLoading])

  const getPendingMovs = () => {
    if(!params?.mov_auth) return;

    setConcilitaionsLoading(true);
    api.get(`requests/${company_hash}/movs/pending/${params?.mov_auth || ''}`)
    .then(response => {
      let itensReponse = response.data.data;
      let items = itensReponse.map((item) => {
        return {
          ...item,
          data: item.data.map((mov) => {
            return {
              ...mov,
            }
          })
        }
      });
      setPendingMovs(items);
    },
    e => {
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar buscar informações sobre as movimentações tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    })
    .finally(() => setConcilitaionsLoading(false));
  };

  const handleSubmit = async () => {
    if(movWithNote?.length != 0) {
      setConcilitaionsLoading(true);
      await api.post(`requests/${company_hash}/movs/pending/${params?.mov_auth || ''}`, {
        movs: movWithNote
      })
      .catch(e => {
        let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar salvar informações sobre as movimentações tente novamente mais tarde'
        ErrorToast({ placeholder: msg })
        console.log(e);
      })
      .finally(() => setConcilitaionsLoading(false));
    }
    if(cardsReceiveWithNote?.length != 0) {
      setConciliationsCompensateCardsReceiveLoading(true);
      await api.post(`requests/${company_hash}/cards-receive/pending/${params?.cards_conciliation || ''}`, {
        movs: cardsReceiveWithNote
      })
      .catch(e => {
        let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar salvar informações sobre as movimentações de recebimento de cartões tente novamente mais tarde'
        ErrorToast({ placeholder: msg })
        console.log(e);
      })
      .finally(() => setConciliationsCompensateCardsReceiveLoading(false));
    }
    if(allowPurchases?.length != 0) {
      setPurchasesLoading(true);
      await api.post(`requests/${company_hash}/purchase-invoices/${params?.purchase_auth}`, {
        purchases: allowPurchases
      })
      .catch(e => {
        let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar salvar informações sobre as notas de compras tente novamente mais tarde'
        ErrorToast({ placeholder: msg })
        console.log(e);
      })
      .finally(() => setPurchasesLoading(false));
    }

    if(installmentsReceiveWithNote?.length != 0) {
      setInstallmentsReceiveLoading(true);
      await api.post(`requests/${company_hash}/installments-receive/${params?.installments_receive_auth || ''}`, {
        installments: installmentsReceiveWithNote
      })
      .catch(e => {
        let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar salvar informações sobre as contas a receber tente novamente mais tarde'
        ErrorToast({ placeholder: msg })
        console.log(e);
      })
      .finally(() => setInstallmentsReceiveLoading(false));
    }

    if(installmentsPaymentWithNote?.length != 0) {
      setInstallmentsPaymentLoading(true);
      await api.post(`requests/${company_hash}/installments-payment/${params?.installments_payment_auth || ''}`, {
        installments: installmentsPaymentWithNote
      })
      .catch(e => {
        let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar salvar informações sobre as contas a pagar tente novamente mais tarde'
        ErrorToast({ placeholder: msg })
        console.log(e);
      })
      .finally(() => setInstallmentsPaymentLoading(false));
    }

    setMovWithNote([]);
    setPendingMovs([]);
    setPendingCardsReceive([]);
    setInstallmentsReceive([]);
    setInstallmentsReceiveWithNote([]);
    setCardsReceiveWithNote([]);
    setInstallmentsPayment([]);
    setInstallmentsPaymentWithNote([]);
    setUpdate(!update);
  }

  const getInstallmentsReceive = () => {
    if(!params?.installments_receive_auth) return;

    setInstallmentsReceiveLoading(true);
    api.get(`requests/${company_hash}/installments-receive/${params?.installments_receive_auth || ''}`)
    .then(response => {
      let itensReponse = response.data;
      let items = itensReponse.map((item) => {
        return {
          ...item,
          due_date: new Date(`${item.due_date}T00:00:00`),
          date: new Date(`${item.date}T00:00:00`)
        }
      });
      setInstallmentsReceive(items);
    },
    e => {
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar buscar informações sobre as movimentações tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    })
    .finally(() => setInstallmentsReceiveLoading(false));
  };

  const getInstallmentsPayment = () => {
    if(!params?.installments_payment_auth) return;

    setInstallmentsPaymentLoading(true);
    api.get(`requests/${company_hash}/installments-payment/${params?.installments_payment_auth || ''}`)
    .then(response => {
      let itensReponse = response.data;
      let items = itensReponse.map((item) => {
        return {
          ...item,
          due_date: new Date(`${item.due_date}T00:00:00`),
          date: new Date(`${item.date}T00:00:00`)
        }
      });
      setInstallmentsPayment(items);
    },
    e => {
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar buscar informações sobre as movimentações tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    })
    .finally(() => setInstallmentsPaymentLoading(false));
  };

  const getPendingConciliatesCardsReceive = () => {
    if(!params?.cards_conciliation) return;

    setConciliationsCompensateCardsReceiveLoading(true);
    api.get(`requests/${company_hash}/cards-receive/pending/${params?.cards_conciliation || ''}`)
    .then(response => {
      let itensReponse = response.data.data;
      let items = itensReponse.map((item) => {
        return {
          ...item,
          data: item.data.map((mov) => {
            return {
              ...mov,
              description: `Recebimento cartão - Autorização: ${mov.autorization_number} - Parcela: ${mov.installments_number}`,
              type: 1
            }
          })
        }
      });
      setPendingCardsReceive(items);
    },
    e => {
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar buscar informações sobre as movimentações de cartões tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    })
    .finally(() => setConciliationsCompensateCardsReceiveLoading(false));
  };

  useEffect(() => {
    getData();
    getPendingMovs();
    getInstallmentsReceive();
    getInstallmentsPayment();
    getPendingConciliatesCardsReceive();
  }, [update]);

  return (
    <>
      <TransitionGroup component="div" appear={true} enter={false} exit={false}>
        <CSSTransition timeout={800} classNames="TabsAnimation">
          <Container fluid className='py-2 mb-5'>
            <Row className='mt-2'>
              <Col md={{ size: 10, order:2, offset: 1}}>
                <PageContent>
                  <h3 className="card-title">Acesso de Convidado</h3>
                  <hr />
                  {
                    companyData ? (
                      <Row className='align-items-center'>
                        <Col md={4} xs="12" className='d-flex justify-content-center d-md-block'>
                          <img src={companyData.logo} alt="Logo" style={{
                            maxWidth: '400px',
                            maxHeight: '80px',
                          }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://via.placeholder.com/400x80.png?text=Sem+Logo';
                          }}
                          />
                        </Col>
                        <Col md={6} xs="12" className='mt-4 mt-md-0 text-center text-md-left'>
                          <Row>
                            <Col md={12}>
                              <h6 className='mb-4 mb-md-0'><b>{companyData.name}</b> - {formatCpfCnpj({value: companyData.cpfcnpj})}</h6>
                              <h6 className='mb-4 mb-md-0'>{ companyData.address }, { companyData.number } { companyData.complement ? ` - ${companyData.complement}` : '' } { companyData.neighborhood }, { companyData.city.name } / { companyData.state.name } - CEP: { companyData.cep }</h6>
                              <h6>
                              { companyData.phone ? formatPhone({value: companyData.phone}) : '' } <div className='d-none d-sm-inline'><span>-</span></div> <br  className='d-md-none'/> { companyData.email }
                              </h6>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : ''}
                  <hr />
                </PageContent>
              </Col>
            </Row>
            {
              params?.mov_auth &&
              <>
                <br />
                <Row className='mt-2'>
                  <Col md={{ size: 10, order:2, offset: 1}}>
                    {
                      concilitaionsLoading ?
                      <PageContent>
                        <Row>
                          <Col md={12}>
                            <h3 className="card-title">Movimentações</h3>
                            <br />
                            <div className='text-center'>
                              <Spinner
                                style={{
                                  height: '3rem',
                                  width: '3rem'
                                }}
                                title='Carregando movimentações'
                                color="primary"
                                className='text-center'
                              />
                            </div>
                          </Col>
                        </Row>
                      </PageContent>
                      :
                      <Conciliations pendingMovs={pendingMovs} setPendingMovs={setPendingMovs} setMovWithNote={setMovWithNote} movWithNote={movWithNote} movAuth={params?.mov_auth} />
                    }
                  </Col>
                </Row>
              </>
            }
            {
              params?.cards_conciliation &&
              <>
                <br />
                <Row className='mt-2'>
                  <Col md={{ size: 10, order:2, offset: 1}}>
                    {
                      conciliationsCompensateCardsReceiveLoading ?
                      <PageContent>
                        <Row>
                          <Col md={12}>
                            <h3 className="card-title">Conciliação de recebimento de cartões</h3>
                            <br />
                            <div className='text-center'>
                              <Spinner
                                style={{
                                  height: '3rem',
                                  width: '3rem'
                                }}
                                title='Carregando movimentações'
                                color="primary"
                                className='text-center'
                              />
                            </div>
                          </Col>
                        </Row>
                      </PageContent>
                      :
                      <CardsConciliations pendingMovs={pendingCardsReceive} setPendingMovs={setPendingCardsReceive} setMovWithNote={setCardsReceiveWithNote} movWithNote={cardsReceiveWithNote} movAuth={params?.cards_conciliation} />
                    }
                  </Col>
                </Row>
              </>
            }
            {
              params?.installments_receive_auth &&
                <>
                  <br />
                  <Row className='mt-2'>
                    <Col md={{ size: 10, order:2, offset: 1}}>
                      {
                        installmentsReceiveLoading ?
                          <PageContent>
                            <Row>
                              <Col md={12}>
                                <h3 className="card-title">Contas a Receber</h3>
                                <br />
                                <div className='text-center'>
                                  <Spinner
                                    style={{
                                      height: '3rem',
                                      width: '3rem'
                                    }}
                                    title='Carregando contas a receber'
                                    color="primary"
                                    className='text-center'
                                  />
                                </div>
                              </Col>
                            </Row>
                          </PageContent>
                          :
                          <InstallmentsReceive
                            installmentsReceive={installmentsReceive}
                            setInstallmentsReceive={setInstallmentsReceive}
                            setInstallmentsReceiveWithNote={setInstallmentsReceiveWithNote}
                            installmentsReceiveWithNote={installmentsReceiveWithNote}
                            installmentsReceiveAuth={params?.installments_receive_auth}
                          />
                      }
                    </Col>
                  </Row>
                </>
            }
            {
              params?.installments_payment_auth &&
                <>
                  <br />
                  <Row className='mt-2'>
                    <Col md={{ size: 10, order:2, offset: 1}}>
                      {
                        installmentsPaymentLoading ?
                          <PageContent>
                            <Row>
                              <Col md={12}>
                                <h3 className="card-title">Contas a Pagar</h3>
                                <br />
                                <div className='text-center'>
                                  <Spinner
                                    style={{
                                      height: '3rem',
                                      width: '3rem'
                                    }}
                                    title='Carregando contas a pagar'
                                    color="primary"
                                    className='text-center'
                                  />
                                </div>
                              </Col>
                            </Row>
                          </PageContent>
                          :
                          <InstallmentsPayment
                            installmentsPayment={installmentsPayment}
                            setInstallmentsPayment={setInstallmentsPayment}
                            setInstallmentsPaymentWithNote={setInstallmentsPaymentWithNote}
                            installmentsPaymentWithNote={installmentsPaymentWithNote}
                            installmentsPaymentAuth={params?.installments_payment_auth}
                          />
                      }
                    </Col>
                  </Row>
                </>
            }
            {
              params?.purchase_auth &&
              <>
                  <br />
                  <Row className='mt-2'>
                    <Col md={{ size: 10, order:2, offset: 1}}>
                    {
                      purchasesLoading ?
                        <PageContent>
                          <Row>
                            <Col md={12}>
                              <h3 className="card-title">Notas de compra</h3>
                              <br />
                              <div className='text-center'>
                                <Spinner
                                  style={{
                                    height: '3rem',
                                    width: '3rem'
                                  }}
                                  title='Carregando notas de compra'
                                  color="primary"
                                  className='text-center'
                                />
                              </div>
                            </Col>
                          </Row>
                        </PageContent>
                        :
                      <PurchasesNotes companyHash={company_hash} update={update} setLoading={setConcilitaionsLoading} allowPurchases={allowPurchases} setAllowPurchases={setAllowPurchases} purchaseAuth={params?.purchase_auth} />
                    }
                    </Col>
                  </Row>
              </>
            }
          </Container>
        </CSSTransition>
      </TransitionGroup>
      {
        params?.mov_auth || params?.purchase_auth || params?.installments_receive_auth || params?.installments_payment_auth || params?.cards_conciliation  ?
          <FormFooter
            showSubmit={1}
            showBack={false}
            showDelete={false}
            showStore={false}
            showStoreMulti={false}
            onSubmit={handleSubmit}
          />
        : ''
      }
    </>
  )
}

export default GuestAccess;
