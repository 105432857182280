import React, { Fragment, useState } from "react";

import { ImExit } from "react-icons/im";
import { Col, Container, Row } from "reactstrap";
import { getInfoUser, logout } from "../../../services/auth";

const Contador = ({ history }) => {
  const [user, setUser] = useState(getInfoUser());

  const contabilUrl =
    process.env.REACT_APP_ENV === "local"
      ? "https://app.nfcontador.com.br"
      : process.env.REACT_APP_ENV === "sandbox"
      ? "https://sandbox.fuganholi-contabil.com.br"
      : "https://app.fuganholi-contabil.com.br";

  const logoutAndRedirect = async () => {
    await logout();
    window.open(contabilUrl, "_self");
  };

  return (
    <Fragment>
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-right">
            <Container fluid className="p-0">
              <Row
                className="flex-nowrap rounded-pill align-items-center p1 mx-0"
                style={{
                  background: "#D9DBF5",
                  minWidth: "145px",
                  maxWidth: "145px",
                }}
              >
                <Col xs={9} className="pr-1">
                  <div className="d-flex flex-column overflow-hidden">
                    <small className="mb-0" style={{fontSize: '0.6rem'}}>Você está acessando o cliente:</small>
                    <strong className="text-truncate" title={user.company.name} style={{fontSize: '0.6rem'}}>
                      {user.company.name}
                    </strong>
                  </div>
                </Col>
                <Col xs={3} className="pl-0">
                  <div
                    className="icon-wrapper icon-wrapper-alt rounded-circle"
                    style={{
                      height: 20,
                      width: 20,
                      cursor: "pointer",
                      minWidth: 20
                    }}
                    onClick={() => logoutAndRedirect()}
                  >
                    <ImExit
                      style={{ margin: "0 auto", zIndex: 99999 }}
                      fontSize="15px"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contador;
